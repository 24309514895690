import { Link } from "gatsby"
import React from 'react'

export default function Footer1() {
  return (
    <footer className="footer-area">
    <div className="footer-widget">
      <div className="container">
        <div className="row footer-widget-wrapper pt-100 pb-70">
          <div className="">
            <div className="footer-widget-box list" style={{textAlign:"center"}}>
              <p><a style={{fontSize: '18px', color: 'white'}} href="https://twitter.com/OnlineGymWork">My twitter @OnlineGymWork</a></p>
            Copyright 2018. <Link to="/sitemap" style={{ color: 'white'}}>Sitemap</Link>.
              None of the authors, contributors, administrators, or anyone else connected ("We") with https://onlinegymworkout.com/, in any way whatsoever, can be responsible for your use of the information contained in or linked from these web pages. The content of articles on this site is for informational purposes only and does not constitute professional advice. We assumes no responsibility or liability for any errors or omissions in the content of this site.  The information contained in this site is provided on an "as is" basis with no guarantees of accuracy, completeness, or usefulness and without any warranties of any kind whatsoever, express or implied.  We do not warrant that this site and any information or material downloaded from this site, will be error-free, omission-free, or omission-free.


            </div></div></div></div></div></footer>
  )

}
