import React from 'react';
import { Link } from "gatsby"

export default function Header1() {
    return (
        <header className="header">
        <div className="main-navigation">
          <nav className="navbar navbar-expand-lg">
            <div className="container-fluid px-lg-5">
              <Link className="navbar-brand" to="/" style={{ color: "white"}}>
                {/* <img src="/assets/img/logo/logo.png" alt="logo" /> */}
                Online Gym Workout
              </Link>
              <div className="mobile-menu-right">
                <a href="#" className="mobile-search-btn search-box-outer"><i className="far fa-search" /></a>
                <button className="navbar-toggler" style={{background:'white', color:'black'}} type="button" data-bs-toggle="collapse" data-bs-target="#main_nav" aria-expanded="false" aria-label="Toggle navigation">
                <span style={{fontSize: "18px"}}>Menu</span>

                  <span className="navbar-toggler-icon"><i className="far fa-stream" /></span>
                </button>
              </div>
              <div className="collapse navbar-collapse" id="main_nav">
                <ul className="navbar-nav">

                  <li className="nav-item"><Link className="nav-link" to="/">Home</Link></li>
                  <li className="nav-item"><Link className="nav-link" to="/blog/">Blog</Link></li>
                  <li className="nav-item"><Link className="nav-link" to="/workouts/">Workouts</Link></li>
                  <li className="nav-item"><Link className="nav-link" to="/exercises/">Exercises</Link></li>
                  {/*<li className="nav-item"><Link className="nav-link" to="/contact/">Contact</Link></li>*/}
                  {/* <li className="nav-item"><Link className="nav-link" to="/contact">Contact</Link></li> */}
                </ul>
                <div className="header-nav-right">
                  {/*<div className="header-nav-search">*/}
                  {/*  <a href="#" className="search-box-outer"><i className="far fa-search" /></a>*/}
                  {/*</div>*/}
                  {/* <div className="header-btn">
                    <a href="#" className="theme-btn">Membership<i className="far fa-arrow-right" /></a>
                  </div> */}
                </div>
              </div>
            </div>
          </nav>
        </div>
      </header>
    )
}
