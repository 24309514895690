import * as React from "react"
import { Link } from "gatsby"
import Footer1 from "./Footer1"
import Header1 from "./Header1"

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location?.pathname === rootPath
  let header

  if (isRootPath) {
    header = (
      <h1 className="main-heading">
        <Link to="/">{title}</Link>
      </h1>
    )
  } else {
    header = (
      <Link className="header-link-home" to="/">
        {title}
      </Link>
    )
  }

  return (
    <div>
    {/* /PRELOADER */}
    {/* Header */}
    <Header1 />

    {/* /Header */}
    {/* Search-Popup Section */}
    <div className="search-popup">
      <button className="close-search"><span className="far fa-times" /></button>
      <form>
        <div className="form-group">
          <input type="search" name="search-field" placeholder="Search Here..." required />
          <button type="submit"><i className="far fa-search" /></button>
        </div>
      </form>
    </div>
    {/* /Search-Popup Section */}
    {/* Main Section */}
    <main className="main">
{children}
    </main>
    {/* /Main Section */}


    <Footer1 />
   {/* Scroll-Top */}
    <a href="#" id="scroll-top"><i className="far fa-long-arrow-up" /></a>
    {/* /Scroll-Top */}
  </div>
  )
  return (
    <div className="global-wrapper" data-is-root-path={isRootPath}>
      <header className="global-header">{header}</header>
      <main>{children}</main>
      <footer>
        © {new Date().getFullYear()}, Built with
        {` `}
        <a href="https://www.gatsbyjs.com">Gatsby</a>
      </footer>
    </div>
  )
}

export default Layout
